import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ModeContext } from '../../contexts/mode/ModeContext';
import Spinner from "react-bootstrap/Spinner";
import UserContext from "../../contexts/user/UserContext";
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "../../assets/css/impactShare.css"; // Create and import your CSS file
import logo from '../../assets/images/nz-logo.png'; // Replace with your actual logo path
import treeIcon from '../../assets/images/trees.png'; // Replace with actual path
import carIcon from '../../assets/images/car.png'; // Replace with actual path
import houseIcon from '../../assets/images/house.png'; // Replace with actual path
import planeIcon from '../../assets/images/plane.png'; // Replace with actual path

import goal1 from '../../assets/images/hung.jpeg'; 
import goal2 from '../../assets/images/3.jpeg'; 
import goal3 from '../../assets/images/4.jpeg'; 
import goal4 from '../../assets/images/6.jpeg'; 
import goal5 from '../../assets/images/7.jpeg'; 
import goal6 from '../../assets/images/8.jpeg'; 
import goal7 from '../../assets/images/11.jpeg'; 
import goal8 from '../../assets/images/12.jpeg'; 
import goal9 from '../../assets/images/13.jpeg'; 
import goal10 from '../../assets/images/14.jpeg'; 
import goal11 from '../../assets/images/15.jpeg'; 
import goal12 from '../../assets/images/17.jpeg';  

const YourImpact = ({ credits }) => {
    const { user } = useContext(UserContext);
    const { company } = useOutletContext();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const { isSandboxMode } = useContext(ModeContext);

    const shareUrl = `${window.location.origin}/share-impact/${company._id}`;

    // Function to copy link to clipboard
    const copyLinkToClipboard = () => {
        navigator.clipboard.writeText(shareUrl).then(() => {
            toast.success('Link copied to clipboard!');
        }).catch(err => {
            console.error('Error copying link: ', err);
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const ordersResponse = await http.get(`${API_ENDPOINTS.ORDERS_SUMMERY}/${company._id}?isSandbox=${isSandboxMode}`);
                setOrders(ordersResponse.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
            finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [user, isSandboxMode]);

    const imgs = [
        goal1,
        goal2,
        goal3,
        goal4,
        goal5,
        goal6,
        goal7,
        goal8,
        goal9,
        goal10,
        goal11,
        goal12,
    ];

    return (
        <div className="share-your-impact-page">
            {/* Section 1: Main Impact Statement */}
            <section className="impact-statement py-5">
                <Container>
                    <Row className="align-items-center">
                        <Col md="6" className="text-center text-md-left">
                            <div className="left-section">
                                <h1 className="impact-title">My Impact</h1>
                            </div>
                        </Col>
                        <Col md="4" className="mt-4 mt-md-0">
                            <div className="right-section p-4" style={{
                                backgroundColor: '#defcf1',
                                borderRadius: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            }}>
                                <p className="impact-subtitle">Since {loading ? <Spinner /> : orders?.lastOrderDetails?.createdOn.split('T')[0]}, we have offset</p>
                                <hr style={{ border: '1px solid #000', width: '90%', margin: '10px auto' }} />
                                <div className="impact-value">
                                    <h2 className="display-3">{loading ? <Spinner /> : orders?.lastOrderDetails?.creditsPurchased * 1000}</h2>
                                    <span>kg of CO₂e</span>
                                </div>
                                <hr style={{ border: '1px solid #000', width: '90%', margin: '10px auto' }} />
                                {/*<div className="share-icons mt-4 text-center">
                                    <a href="#" className="item-icon"><i className="bi bi-linkedin mx-2"></i></a>
                                    <a href="#" className="item-icon"><i className="bi bi-x mx-2"></i></a>
                                    <a href="#" className="item-icon"><i className="bi bi-facebook mx-2"></i></a>
                                    <a href="#" className="item-icon" onClick={copyLinkToClipboard}><i className="bi bi-link mx-2"></i></a>
                                </div>*/}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Section 2: Climate Impact Comparisons */}
            <section className="climate-impact py-5">
                <Container>
                    <Row className="text-center">
                        <Col md="3" xs="6" className="mb-4">
                            <div className="impact-item" style={{
                                borderRadius: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            }}>
                                <img src={treeIcon} alt="Trees Planted" className="mb-3" style={{ maxWidth: '150px', borderRadius: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', }} />
                                <h3>{orders?.lastOrderDetails?.creditsPurchased ? (orders?.lastOrderDetails?.creditsPurchased * 16.5).toFixed(2) : 0}</h3>
                                <p>new trees planted</p>
                            </div>
                        </Col>
                        <Col md="3" xs="6" className="mb-4">
                            <div className="impact-item" style={{
                                borderRadius: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            }}>
                                <img src={carIcon} alt="Cars off the Road" className="mb-3" style={{ maxWidth: '150px', borderRadius: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', }} />
                                <h3>{orders?.lastOrderDetails?.creditsPurchased? (orders?.lastOrderDetails?.creditsPurchased * 0.2).toFixed(2) : 0}</h3>
                                <p>cars off the road for a year</p>
                            </div>
                        </Col>
                        <Col md="3" xs="6" className="mb-4">
                            <div className="impact-item" style={{
                                borderRadius: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            }}>
                                <img src={houseIcon} alt="Homes Energy Usage" className="mb-3" style={{ maxWidth: '150px', borderRadius: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',}} />
                                <h3>{orders?.lastOrderDetails?.creditsPurchased? (orders?.lastOrderDetails?.creditsPurchased * 0.1).toFixed(2) : 0}</h3>
                                <p>homes' annual energy usage</p>
                            </div>
                        </Col>
                        <Col md="3" xs="6" className="mb-4">
                            <div className="impact-item" style={{
                                borderRadius: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            }}>
                                <img src={planeIcon} alt="Flights" className="mb-3" style={{ maxWidth: '150px', borderRadius: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', }} />
                                <h3>{orders?.lastOrderDetails?.creditsPurchased ? (orders?.lastOrderDetails?.creditsPurchased * 3.6).toFixed(2) : 0}</h3>
                                <p>flights from Riyadh to London</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Section 3: UN Sustainable Development Goals */}
            <section className="sustainable-goals bg-light py-5">
                <Container>
                    <h2 className="text-center mb-4">Kingdom’s Sustainable Development Goals</h2>
                    <p className="text-center mb-5">Our projects support the following Kingdom’s Sustainable Development Goals</p>
                    <Row className="text-center">
                        {imgs.map((img, i) => (
                            <Col lg="2" md="3" xs="4" key={i} className="mb-4">
                                <a href="link-to-goal-1" target="_blank">
                                    <img src={img} alt="No Poverty" className="mb-3" style={{ maxWidth: '80px' }} />
                                </a>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
            <ToastContainer />
        </div>
    );
};

export default YourImpact;
