import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, NavItem, Collapse } from 'reactstrap';
import { ModeContext } from '../contexts/mode/ModeContext.js'; // Import the context
import logo from '../assets/images/nz-logo.png'; // Adjust the path as necessary
import UserContext from "../contexts/user/UserContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import '../assets/css/sidebar.css'; // Ensure you're importing the CSS correctly

const Sidebar = ({ company }) => {
    const { user } = useContext(UserContext);
    const [isCollapsed, setIsCollapsed] = useState(window.innerWidth <= 768);
    const [impactOpen, setImpactOpen] = useState(false); // State for Impact collapse
    const [developersOpen, setDevelopersOpen] = useState(false); // State for Developers collapse
    const [adminOpen, setAdminOpen] = useState(false); // State for Admin collapse
    const { isSandboxMode, setIsSandboxMode } = useContext(ModeContext); // Use the context

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setIsCollapsed(true);
            } else {
                setIsCollapsed(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleModeToggle = () => {
        setIsSandboxMode(!isSandboxMode);
    };

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };

    const toggleImpact = () => setImpactOpen(!impactOpen);
    const toggleDevelopers = () => setDevelopersOpen(!developersOpen);
    const toggleAdmin = () => setAdminOpen(!adminOpen); // Toggle for Admin

    return (
        <>
            <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
                <div className="sidebar-header">
                    <a href="/profile/home"><img src={logo} alt="Logo" className="logo" /></a>
                    <h3>{company.name}</h3>
                    <p><i className="bi bi-pin-map-fill"></i> {company.location}</p>
                </div>
                <hr className="divider" />
                <Nav vertical>

                                        {/* Admin Section - Only for CM_ADMIN users */}
                                        {user?.accountType === 'CM_ADMIN' && (
                        <>
                            <NavItem onClick={toggleAdmin} style={{ cursor: 'pointer' }}>
                                <div className="nav-link">
                                    <i className="bi bi-person-badge"></i> Admin
                                    <FontAwesomeIcon
                                        icon={adminOpen ? faChevronDown : faChevronRight}
                                        className="arrow" />
                                </div>
                            </NavItem>
                            <Collapse isOpen={adminOpen}>
                                <NavItem>
                                    <NavLink to="/profile/admin/leads" className="nav-link sub-link">
                                        <i className="bi bi-people"></i> Leads
                                    </NavLink>
                                </NavItem>
                                {/*<NavItem>
                                    <NavLink to="/profile/admin/portfolio" className="nav-link sub-link">
                                        <i className="bi bi-briefcase-fill"></i> Portfolio
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to="/profile/admin/projects" className="nav-link sub-link">
                                        <i className="bi bi-bar-chart"></i> Projects
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to="/profile/admin/credits" className="nav-link sub-link">
                                        <i className="bi bi-cash-stack"></i> Credits
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to="/profile/admin/accounts" className="nav-link sub-link">
                                        <i className="bi bi-person-circle"></i> Accounts
                                    </NavLink>
                                </NavItem>*/}
                            </Collapse>
                        </>
                    )}

                    {/* Dashboard */}
                    <NavItem>
                        <NavLink to="/profile/home" className="nav-link"><i className="bi bi-house"></i> Home</NavLink>
                    </NavItem>

                    {/* Offset */}
                    <NavItem>
                        <NavLink to="/profile/offset" className="nav-link"><i className="bi bi-currency-exchange"></i> Offset</NavLink>
                    </NavItem>

                    {/* Portfolio */}
                    <NavItem>
                        <NavLink to="/profile/choose-portfolio" className="nav-link"><i className="bi bi-briefcase"></i> Explore Portfolios</NavLink>
                    </NavItem>

                    {/* Orders */}
                    <NavItem>
                        <NavLink to="/profile/orders" className="nav-link"><i className="bi bi-list-task"></i> Orders</NavLink>
                    </NavItem>

                    {/* Impact Section */}
                    <NavItem onClick={toggleImpact} style={{ cursor: 'pointer' }}>
                        <div className="nav-link">
                            <i className="bi bi-globe-americas"></i> Impact
                            <FontAwesomeIcon
                                icon={impactOpen ? faChevronDown : faChevronRight}
                                className="arrow" />
                        </div>
                    </NavItem>
                    <Collapse isOpen={impactOpen}>
                        <NavItem>
                            <NavLink to="/profile/my-impact" className="nav-link sub-link">
                                <i className="bi bi-tree"></i> My Impact
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="/profile/impact-sharing" className="nav-link sub-link">
                                <i className="bi bi-megaphone"></i> Sharing
                            </NavLink>
                        </NavItem>
                    </Collapse>

                    {/* Developers Section */}
                    <NavItem onClick={toggleDevelopers} style={{ cursor: 'pointer' }}>
                        <div className="nav-link">
                            <i className="bi bi-code-slash"></i> Developers
                            <FontAwesomeIcon
                                icon={developersOpen ? faChevronDown : faChevronRight}
                                className="arrow" />
                        </div>
                    </NavItem>
                    <Collapse isOpen={developersOpen}>
                        <NavItem>
                            <NavLink to="/profile/developer" className="nav-link sub-link">
                                <i className="bi bi-file-code"></i> APIs
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="/profile/widgets" className="nav-link sub-link">
                                <i className="bi bi-window-desktop"></i> Widgets
                            </NavLink>
                        </NavItem>
                    </Collapse>

                    {/* Sandbox Mode Toggle */}
                    <div className="sandbox-toggle mb-3">
                        <label className="switch">
                            <input type="checkbox" checked={isSandboxMode} onChange={handleModeToggle} />
                            <span className="slider round"></span>
                        </label>
                        <span className="toggle-label">Sandbox Mode</span>
                    </div>
                </Nav>
            </div>
            <button className="sidebar-toggle" onClick={toggleSidebar}>
                {isCollapsed ? 'Expand' : 'Collapse'}
            </button>
        </>
    );
};

export default Sidebar;
