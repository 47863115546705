import React, { useState, useEffect } from "react";
import NavBar from "../../Layout/Navbar";
import Section from "./Section";
import Services from "../../components/Services";
import About from "../../components/About";
import Process from "../../components/Process";
import Counter from "../../components/Counter";
import Testimonial from "../../components/Testimonial";
import Team from "../../components/Team";
import Portfolio from "../../components/Portfolio";
import Pricing from "../../components/Pricing";
import Integration from "../../components/Integration";
import Blog from "../../components/Blog";
import CTA from "../../components/CTA";
import Contact from "../../components/Contact";
import Footer from "../../Layout/Footer";
import FooterAlt from "../../Layout/FooterAlt";
import BackToTop from "../../components/BackToTop";
import APIFeatures from "../../components/APIFeatures";
import ProductsSection from "../../components/ProductsSection";
import ImpactSection from "../../components/ImpactSection";

const Layout5 = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    // Check the user's color scheme preference
    const darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    setIsDarkMode(darkModeMediaQuery.matches);

    // Add event listener to handle changes in the color scheme
    const handleChange = (e) => setIsDarkMode(e.matches);
    darkModeMediaQuery.addEventListener("change", handleChange);

    // Cleanup the event listener on component unmount
    return () => darkModeMediaQuery.removeEventListener("change", handleChange);
  }, []);

  return (
    <React.Fragment>
      <NavBar isDark={isDarkMode} />
      <Section />
      <Services />
      <About />
      <Integration />
      <Process />
      <APIFeatures />
      <ProductsSection />
      <ImpactSection />
      {/*<Counter />
      <Testimonial />
      <Portfolio />
      <Pricing />
      <Team />*/}
      <CTA />
      <Blog />
      <Contact />
      <Footer />
      <FooterAlt />
      <BackToTop />
    </React.Fragment>
  );
};

export default Layout5;
