export const API_ENDPOINTS = {
    SECTORS: '/sectors',
    SAVE_COMPANY_DATA: '/company/save-company-data',
    LOGIN: '/user/login',
    SIGNUP: '/user/signup',
    SCOPES_EMISSIONS: '/emissions/scopes',
    TOP_CONTRIBUTORS: '/emissions/top-emissions',
    FULL_REPORT: '/emissions/full-report',
    COMPANY: '/company',
    PURCHASE_ORDER: '/company/order',
    COMPANY_ORDERS_SUMMERY: '/company/ordersSummery',
    COMPANY_ORDERS: '/company/orders',
    PORTFOLIO: '/portfolio',
    GET_PORTFOLIO: '/portfolio/getPortfolio',
    INQUIRY: '/mail/inquiry',
    ORDER_DETAILS: '/order',
    CREATE_SANDBOX_APIKEY: '/apikeys/create',
    REVOKE_SANDBOX_APIKEY: '/apikeys/revoke',
    GET_LIVE_API_KEYS: '/apikeys/live',
    GET_SANDBOX_API_KEYS: '/apikeys/sandbox',
    PUBLIC_SHARE_IMPACT: '/public/share-impact',
    PROJECT: '/project',
    PROJECTS_BY_IDS: '/project/byIds',
    PROJECT_RECORDS_BY_IDS: '/project/projectRecordsById',
    ORDERS_SUMMERY: '/order/summery',
    ORDERS: '/order/orders-list',
    SUBMIT_ORDER: '/order',
    SUPPORTED_PROJECTS: '/project/supported',
    LEADS: '/admin/leads',
    LEAD: '/admin/leads'
}